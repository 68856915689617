import Vue from 'vue'
import Vuex from 'vuex'
import VueGapi from 'vue-gapi'

import auth from '@/store/modules/auth.js'
import contact from '@/store/modules/contact.js'
import account from '@/store/modules/account.js'

import name from '@/store/modules/name.js'
import phone from '@/store/modules/phone.js'
import email from '@/store/modules/email.js'

import share from '@/store/modules/share.js'

import google from '@/store/modules/google.js'

Vue.use(VueGapi, {
  apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
  clientId: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID,
  discoveryDocs: ['https://people.googleapis.com/$discovery/rest?version=v1'],
  scope: 'https://www.googleapis.com/auth/contacts',
})

Vue.use(Vuex)

Vuex.Store.prototype.$gapi = Vue.prototype.$gapi

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    contact,
    account,
    share,
    name,
    phone,
    email,
    google
  },
  strict: debug
})
