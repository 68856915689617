<template>
  <b-container>
    <b-row>
      <b-col>
        <p>
          Currently you can only share your details with people by providing their email address.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="kit-live-data-header">
        <h1>
          Sharing

          <b-icon icon="question-circle-fill" v-b-popover.click.blur="'You can share your details with others via email address whether they use this service or not. This shows the current set of email addresses your live details are shared with.'" ></b-icon>

          <b-icon icon="plus-circle-fill" v-on:click="add"></b-icon>
        </h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p>
          The following email addresses can see your details.
        </p>
        <p>
          To check your details from their point of view click the eye <b-icon icon="eye"></b-icon>.
        </p>
      </b-col>
    </b-row>
    <Share v-for="item in shares" :key="item.id" v-bind="item"/>
  </b-container>
</template>

<script>
import Share from '@/components/Share.vue'

export default {
  name: 'SharePanel',
  components: { Share },
  props: {
    shares: { type: Array, required: true }
  },
  data: function () {
    return {
      adding: false
    }
  },
  methods: {
    add: function() {
      this.$store.dispatch('share/new')
    }
  },
}
</script>

<style scoped lang="scss">
</style>
