<template>
  <b-row v-if="showEdit" class="kit-live-data editing">
    <b-col sm>
      <input type="text" v-model="$data.scratch.email" placeholder="Email">
    </b-col>
    <b-col sm>
      <input type="text" v-model="$data.scratch.vistag" placeholder="Tags">
    </b-col>
    <b-col sm>
      <b-button class="kit-inline-button" xvariant="primary" v-on:click="save">
        <b-icon icon="check2"></b-icon>
      </b-button>
      <b-button class="kit-inline-button" xvariant="primary" v-on:click="cancel">
        <b-icon icon="x-circle-fill"></b-icon>
      </b-button>
    </b-col>
  </b-row>
  <b-row v-else class="kit-live-data">
    <b-col sm>
        <b-icon v-if="dirty" icon="arrow-clockwise" animation="spin-pulse"></b-icon> {{ email }}
    </b-col>
    <b-col sm>
      <b-icon icon="tag"></b-icon>
      <span v-for="item in vistag" :key="item">
        <b-badge variant="info" class="kit-vistag">{{ item }}</b-badge>&nbsp;
      </span>
    </b-col>
    <b-col sm>
      <b-button size="sm" class="kit-inline-button" xvariant="primary" v-on:click="edit">
        <b-icon icon="pencil"></b-icon>
      </b-button>
      <b-button size="sm" class="kit-inline-button" xvariant="primary" v-on:click="confirmRemove">
        <b-icon icon="trash"></b-icon>
      </b-button>
      <b-button size="sm" class="kit-inline-button" xvariant="primary" v-on:click="pointOfView">
        <b-icon icon="eye"></b-icon>
      </b-button>
    </b-col>
    <b-modal :id="modalId" hide-footer>

      <template #modal-title>
        <p>{{email}}'s view</p>
      </template>

      <b-container fluid>
        <b-row>
          <b-col>
            <ContactCard v-bind="povData"/>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </b-row>
</template>

<script>
import ContactCard from '@/components/contacts/ContactCard.vue'

export default {
  name: 'Share',
  components: {
    ContactCard
  },
  props: {
    id: { type: String, required: true },
    email: { type: String, required: true },
    vistag: { type: Array, required: true },
    accepted_at: { type: String, required: false },
    created_at: { type: String, required: false },
    updated_at: { type: String, required: false },
    dirty: { type: Boolean, required: false, default: false },
    forNew: { type: Boolean, required: false, default: false }
  },
  data: function() {
    return {
      modalId: "pov-" + this.$props.id,
      editing: false,
      scratch: {
        label: null,
        value: null,
        vistag: null,
        accepted_at: null,
        created_at: null,
        updated_at: null,
      }
    }
  },

  computed: {
    showEdit: function() {
      return ( this.$data.editing || this.$props.forNew )
    },
    povData: function() {
      return this.$store.getters['account/pointOfView'](this.$props.vistag);
    }
  },

  methods: {
    edit: function() {
      console.log("edit")
      this.$rollbar.info('share:edit');
      this.$data.editing = true;
      this.$data.scratch = {
        email: this.$props.email,
        vistag: this.$props.vistag.join(" "),
        accepted_at: this.$props.accepted_at,
        created_at: this.$props.created_at,
        updated_at: this.$props.updated_at,
        dirty: true
      }

      console.log(this.$data.scratch)
    },

    cancel: function() {
      console.log("cancel")
      this.$rollbar.info('share:cancel');

      if(this.$props.forNew) {
        this.$store.commit('share/remove', this.$props.id)
      } else {
        this.$data.editing = false;
      }
    },

    save: function() {
      console.log("save")
      this.$rollbar.info('share:save');

      const data = Object.assign({ id: this.$props.id, dirty: this.$props.dirty }, this.$data.scratch);
      data.vistag = data.vistag.split(" ")

      if(this.$props.forNew) {
        this.$store.dispatch('share/create', data)
      } else {
        this.$store.dispatch('share/update', data)
        this.$data.editing = false;
      }
    },

    remove: function() {
      console.log("remove")
      this.$rollbar.info('share:remove');
      this.$store.dispatch('share/remove', this.$props.id)
    },

    confirmRemove: function() {
      this.$bvModal.msgBoxConfirm(
        'Delete share: "' + this.$props.label + '"? There is currently no way to undo this.',
        {
          okTitle: 'Delete',
          okVariant: 'danger',
          cancelTitle: 'Keep',
          cancelVariant: 'primary'
        }
        )
      .then(value => {
        console.log(value)
        if(value) {
          this.remove()
        } else {
          console.log("ignore remove")
        }
      })
      .catch(err => {
        // An error occurred
        console.log("err on conrifm remove:" + err)
      })
    },

    pointOfView: function() {
      console.log("TODO: point of view modal.... for id: " + this.$data.modalId)

      this.$bvModal.show(this.$data.modalId)
    }

  }
}
</script>

<style lang="scss">
</style>
