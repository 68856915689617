
import { GooglePerson } from '@/domain/GooglePerson'

const externalId = function (domain) {
  return 'kontakt.v1.' + domain.id;
}

const domainToGoogle = function (domain) {

  const nameParts = domain.names[0].value.split(' ')

  var name = null;
  if (nameParts.length > 1) {
    name = {
      displayName: domain.names[0].value,
      givenName: nameParts[0],
      familyName: nameParts.slice(1).join(' '),
    }
  } else {
    name = {
      displayName: domain.names[0].value,
      givenName: domain.names[0].value,
    }
  }

  var emails = domain.emails.map((email) => {
    return {
      value: name.displayName.toString() + " (" + email.label.toString() + ") <" + email.value.toString() + ">",
      type: 'other'
    }
  })

  var phones = domain.phones.map((phone) => {
    return {
      value: phone.value.toString(),
      type: 'other'
    }
  })

  return {
    names: [name],
    emailAddresses: emails,
    phoneNumbers: phones,
    externalIds: [{
      value: externalId(domain),
      type: 'Kontaktable',
    }]

  }
}

const domainToGooglePerson = function(domain) {
  return new GooglePerson(domainToGoogle(domain))
}


export { domainToGoogle, domainToGooglePerson };
