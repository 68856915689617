<template>
<div>
  <LoginForm/>
  <b-container>
    <b-row>
      <b-col>
        <hr>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p>Not registered? <router-link to="/register">Sign Up Now!</router-link></p>
      </b-col>
    </b-row>
  </b-container>

</div>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue'

export default {
  name: 'Login',
  components: {
    LoginForm
  }
}
</script>
