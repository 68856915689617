<template>
  <b-row v-if="showEdit" class="kit-live-data editing">
    <b-col sm="2">
      <input type="text" v-model="$data.scratch.label" placeholder="Label" size="10">
    </b-col>
    <b-col sm="4">
      <input type="text" v-model="$data.scratch.value" placeholder="Name">
    </b-col>
    <b-col sm="4">
      <input type="text" v-model="$data.scratch.vistag" placeholder="Tags">
    </b-col>
    <b-col sm="2">
      <b-button size="sm" class="kit-inline-button" xvariant="primary" v-on:click="save">
        <b-icon icon="check2"></b-icon>
      </b-button>
      <b-button size="sm" class="kit-inline-button" xvariant="primary" v-on:click="cancel">
        <b-icon icon="x-circle-fill"></b-icon>
      </b-button>
    </b-col>
  </b-row>
  <b-row v-else class="kit-live-data">
    <b-col sm="2">
      <b-icon v-if="dirty" icon="arrow-clockwise" animation="spin-pulse"></b-icon> {{ label }}
    </b-col>
    <b-col sm="4">
      {{ value }}
    </b-col>
    <b-col sm="4">
      <b-icon icon="tag"></b-icon>
      <b-badge variant="info" class="kit-vistag" v-for="item in vistag" :key="item">{{ item }}</b-badge>
    </b-col>
    <b-col sm="2">
      <b-button size="sm" class="kit-inline-button" xvariant="primary" v-on:click="edit">
        <b-icon icon="pencil"></b-icon>
      </b-button>
      <b-button size="sm" class="kit-inline-button" xvariant="primary" v-on:click="confirmRemove">
        <b-icon icon="trash"></b-icon>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'LiveName',
  props: {
    id: { type: String, required: true },
    label: { type: String, required: true },
    value: { type: String, required: true },
    vistag: { type: Array, required: true },
    dirty: { type: Boolean, required: false, default: false },
    forNew: { type: Boolean, required: false, default: false }
  },
  data: function() {
    return {
      editing: false,
      scratch: {
        label: null,
        value: null,
        vistag: null,
      }
    }
  },

  computed: {
    showEdit: function() {
      return ( this.$data.editing || this.$props.forNew )
    }
  },

  methods: {
    edit: function() {
      console.log("edit")
      this.$rollbar.info('live-name:edit');
      this.$data.editing = true;
      this.$data.scratch = {
        label: this.$props.label,
        value: this.$props.value,
        vistag: this.$props.vistag.join(" "),
        dirty: true
      }

      console.log(this.$data.scratch)
    },

    cancel: function() {
      console.log("cancel")
      this.$rollbar.info('live-name:cancel');

      if(this.$props.forNew) {
        this.$store.commit('name/remove', this.$props.id)
      } else {
        this.$data.editing = false;
      }
    },

    save: function() {
      console.log("save")
      this.$rollbar.info('live-name:save');

      const data = Object.assign({ id: this.$props.id, dirty: this.$props.dirty }, this.$data.scratch);
      data.vistag = data.vistag.split(" ")

      if(this.$props.forNew) {
        this.$store.dispatch('name/create', data)
      } else {
        this.$store.dispatch('name/update', data)
        this.$data.editing = false;
      }
    },

    remove: function() {
      console.log("remove")
      this.$rollbar.info('live-name:remove');
      this.$store.dispatch('name/remove', this.$props.id)
    },

    confirmRemove: function() {
      this.$bvModal.msgBoxConfirm(
        'Delete name: "' + this.$props.label + '"? There is currently no way to undo this.',
        {
          okTitle: 'Delete',
          okVariant: 'danger',
          cancelTitle: 'Keep',
          cancelVariant: 'primary'
        }
        )
      .then(value => {
        console.log(value)
        if(value) {
          this.remove()
        } else {
          console.log("ignore remove")
        }
      })
      .catch(err => {
        // An error occurred
        console.log("err on conrifm remove:" + err)
      })
      },


  }
}
</script>

<style lang="scss">
</style>
