import axios from 'axios'
import Vue from 'vue'

function fromApi(api) {
  return Object.assign({
    dirty: false,
    forNew: false
  }, api)
}

const state = {
  data: {},
}

const getters = {
  all: state => {
    return Object.values(state.data)
  }
}

const actions = {
  // add a list of JSON objects to the store
  add_list(context, arr) {
    console.log("email:add_list")
    arr.forEach(data => {
      // add properties we want in our store
      context.commit('add', fromApi(data))
    });
  },

  // create a new, blank, email for editing before being sent to the server
  new(context) {
    console.log("email:new")
    let data = {
      id: 'temp-id',
      label: '',
      value: '',
      vistag: [],
      dirty: true,
      forNew: true,
    }
    context.commit('add', data)
  },

  // POST data to server to create a new email
  create(context, data) {
    console.log('email:create')
    console.log(data)

    context.commit('update', data)

    console.log('call API')
    axios.post('emails', data)
      .then(response => {
        console.log("email create api status: " + response.status + " " + response.statusText)
        console.log('response.data;')
        console.log(response.data)

        context.commit('remove', data.id)
        context.commit('add', fromApi(response.data))
      }, err => {
        console.log("failed to update email " + err)
        // TODO: alert user if we fail to make the change and reset to original somehow?
      })
  },

  update(context, data) {
    console.log('email:update')
    console.log(data)

    context.commit('add', data)

    console.log('call API to update email')
    axios.put('emails/' + data.id, data)
      .then(response => {
        console.log(response.data)
        context.commit('add', fromApi(response.data))
      }, err => {
        console.log("failed to update email " + err)
        // TODO: alert user if we fail to make the change and reset to original somehow?
      })

  },

  remove(context, id) {
    console.log('email:remove')
    console.log(id)

    context.commit('set_dirty', id)

    console.log('call API to update email')
    axios.delete('emails/' + id)
      .then(response => {
        console.log(response.data)
        context.commit('remove', id)
      }, err => {
        console.log("failed to remove email " + err)
        // TODO: alert user if we fail to make the change and reset to original somehow?
      })

  },

}

const mutations = {
  set_dirty(state, id) {
    // TODO: test if this makes things work or not
    state.data[id].dirty = true

    //Vue.set(state.data, id, Object.assign({dirty: true}, state.data[id]))
  },

  set_clean(state, id) {
    // TODO: test if this makes things work or not
    state.data[id].dirty = false
  },

  update(state, data) {
    // TODO: test
    Vue.set(state.data, data.id, data)
  },

  add(state, data) {
    // TODO: test
    Vue.set(state.data, data.id, data)
  },

  remove(state, id) {
    // TODO: test
    Vue.delete(state.data, id)
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
