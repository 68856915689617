<template>
  <b-container v-if="loaded">
    <SharePanel v-bind:shares="shares" />
  </b-container>
  <b-container v-else>
    <b-row>
      <b-col><h2>Loading...</h2></b-col>
    </b-row>
  </b-container>
</template>

<script>
import SharePanel from '@/components/SharePanel.vue'

export default {
  name: 'Sharing',
  components: { SharePanel },
  data: function() {
    return {
      error: null,
      showDebug: (process.env.VUE_APP_SHOW_DEBUG_INFO == 'true')
    }
  },
  computed: {
    loaded: function() {
      return this.$store.getters['account/loaded']
    },
    account: function() {
      return this.$store.getters['account/current']
    },
    shares: function() {
      return this.$store.getters['share/all']
    }
  },
  mounted() {
    console.log("sharing mounted...")
    this.$store.dispatch('account/ensureLoad')
  },
}
</script>

<style scoped lang="scss">
</style>
