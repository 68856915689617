<template>
  <div>
    <GoogleAccount />
    <hr>
    <!-- GoogleContacts / -->
  </div>
</template>

<script>
//import GoogleContacts from '@/components/GoogleContacts.vue'
import GoogleAccount from '@/components/google/GoogleAccount.vue'


export default {
  name: 'Google',
  components: {
    GoogleAccount,
//    GoogleContacts
  }
}
</script>
