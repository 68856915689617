<template>
  <b-container v-if="loaded">
    <b-row>
      <b-col>
        <b-table-simple v-if="this.$data.showDebug" :bordered="true" :striped="true">
          <caption>Account debug info.</caption>
          <b-tbody>
            <b-tr><b-td>ID</b-td><b-td>{{ account.id }}</b-td></b-tr>
            <b-tr><b-td>E-Mail</b-td><b-td>{{ account.email }}</b-td></b-tr>
            <b-tr><b-td>Created</b-td><b-td>{{ account.created_at }}</b-td></b-tr>
            <b-tr>
              <b-td colspan="2">
                <b-button class="kit-inline-button" xvariant="primary" v-on:click="reload">
                  RELOAD <b-icon icon="arrow-clockwise"></b-icon>
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-container>
  <b-container v-else>
    <b-row>
      <b-col><h2>Loading...</h2></b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Debug',
  data: function() {
    return {
      error: null,
      showDebug: (process.env.VUE_APP_SHOW_DEBUG_INFO == 'true')
    }
  },
  computed: {
    loaded: function() {
      return this.$store.getters['account/loaded']
    },
    account: function() {
      return this.$store.getters['account/current']
    },
    names: function() {
      return this.$store.getters['name/all']
    },
    phones: function() {
      return this.$store.getters['phone/all']
    },
    emails: function() {
      return this.$store.getters['email/all']
    },
    shares: function() {
      return this.$store.getters['share/all']
    }
  },
  methods: {
    reload: function() {
      this.$store.dispatch('account/performLoad')
    }
  },
  mounted() {
    console.log("account mounted...")
    this.$store.dispatch('account/ensureLoad')
  },
}
</script>

<style scoped lang="scss">
</style>
