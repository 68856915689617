import axios from 'axios'

const TOKEN_KEY = 'authToken'

const state = {
  error: null,
  token: null,
}

const getters = {
  authenticated: state => {
    return (state.token != null)
  },

  token: state => {
    return state.token
  },
}

const actions = {
  login(context, value) {
    console.log("login")
    console.log(value)

    console.log("base url")
    console.log(axios.defaults.baseURL)

    axios.post('api-keys', value,
      { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } }
    ).then(
      res => {
        console.log("api key status: " + res.status)
        context.commit('set_token', res.data.token)
    }).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response);
        console.log(error.response.status);
        console.log(error.response.headers);

        context.commit('set_error', 'Server said - ' + error.response.statusText + ' with code:' + error.response.status)
      } else if (error.request) {
        // The request was made but no response was received
        console.log("made request, no response")
        console.log(error.request);
        context.commit('set_error', "No response from server...oops...try again in a wee bit?")
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        context.commit('set_error', "Aaaaarrrgfgghhhhhhhh..........PANIC!")
      }
    });
  },

  logout(context) {
    context.commit('set_token', null)
    context.commit('set_error', null)
  },

  loadPersisted(context) {
    console.log("attempting to load persisted token from local storage")
    let xxx = window.localStorage.getItem(TOKEN_KEY)

    console.log(typeof xxx)
    console.log(xxx)

    if (xxx) {
      console.log("xxx auth token from store: " + xxx)
      context.commit('set_token', xxx)
    } else {
      console.log("no auth token persisted")
    }
  },

  register(context, value) {
    console.log("register")
    console.log(value)

    context.commit('set_token', null)
    context.commit('set_error', null)

    axios.post('accounts', value,
      { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } }
    ).then(
      res => {
        console.log("account create status: " + res.status)
        context.dispatch('login', value)
    }).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response);
        console.log(error.response.status);
        console.log(error.response.headers);

        context.commit('set_error', 'Server said - ' + error.response.statusText + ' with code:' + error.response.status)
      } else if (error.request) {
        // The request was made but no response was received
        console.log("made request, no response")
        console.log(error.request);
        context.commit('set_error', "No response from server...oops...try again in a wee bit?")
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        context.commit('set_error', "Aaaaarrrgfgghhhhhhhh..........PANIC!")
      }
    });
  },

}

const mutations = {
  set_token(state, value) {
    state.token = value
    window.localStorage.setItem(TOKEN_KEY, value)
    state.error = null
  },

  set_error(state, value) {
    state.token = null
    window.localStorage.removeItem(TOKEN_KEY)
    state.error = value
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
