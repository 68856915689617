export class GooglePerson {
  constructor(data) {
    this.data = data;
    this.kontaktableId = null
    this.externalIds = (this.data.externalIds ?? [])
    let kids = this.externalIds.filter((x) => { return x.type == 'Kontaktable'; })
    if (kids.length > 0) {
      this.kontaktableId = kids[0].value
    }
  }

  isKontaktable() {
    return (!!this.kontaktableId);
  }

  toString() {
    return "Person - " + this.data.names[0].displayName + " " + (this.kontaktableId ?? "<NOID>")
  }
}
