<template>
    <b-container class="kit-google-synch">
      <b-row v-if="loading">
        <b-col>
          <p><b-icon icon="google"></b-icon> - Setting up Google API...</p>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col v-if="signedIn">
          <p><b-icon icon="google"></b-icon>
            <img :src="profile.getImageUrl()" width="24" height="24"/> {{ profile.getName() }} <!-- ({{ profile.getEmail() }}) -->
            <b-button class="kit-inline-button" v-on:click="logout">Logout</b-button>
            <b-button class="kit-inline-button" v-on:click="synch">Synchronize <b-icon icon="arrow-clockwise"></b-icon></b-button>
            <!-- ({{ contactCount }} currently)-->
          </p>
          <p v-if="message">{{ message }}</p>
        </b-col>
        <b-col v-else>
          <b-icon icon="google"></b-icon> <b-button variant="primary" v-on:click="login">Google Synch</b-button>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
/* eslint-disable */
import { domainToGooglePerson } from '@/conversion/google_people'

export default {
  name: "GoogleAccount",
  components: {},
  data: function () {
    return {
      message: null,
    };
  },
  methods: {
    login: function () {
      this.$store.dispatch('google/login');
    },
    logout: function () {
      this.$store.dispatch('google/logout');
    },
    synch: function () {
      let contacts = this.$store.getters['contact/all'];

      // synch each one
      contacts.forEach(contact => {
        let person = domainToGooglePerson(contact)
        console.log(person.toString())
        this.$store.dispatch('google/updateContact', person);
      });

      window.alert("Synchronized to Google")
    },
  },

  computed: {
    loading: function () {
      return (!this.$store.getters["google/initialised"]);
    },
    signedIn: function () {
      return this.$store.getters["google/signedIn"];
    },
    user: function () {
      return this.$store.getters["google/user"];
    },
    profile: function () {
      return this.$store.getters["google/profile"];
    },
    contactCount: function () {
      return this.$store.getters["google/count"];
    },
  },

  created() {
    this.$store.dispatch('google/initialise');
  },

};
</script>

<style scoped lang="scss">
.kit-google-synch {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
