import axios from 'axios'

const state = {
  error: null,
  loaded: false,
  data: null,
}

const getters = {
  loaded: state => {
    return state.loaded
  },

  current: state => {
    return state.data
  },

  pointOfView: (state) => (tags) => {

    let names = state.data.names
      .filter(name => {
        return tags.filter(tag => name.vistag.includes(tag)).length != 0
    })

    let emails = state.data.emails
      .filter(email => {
        return tags.filter(tag => email.vistag.includes(tag)).length != 0
    })

    let phones = state.data.phones
      .filter(phone => {
        return tags.filter(tag => phone.vistag.includes(tag)).length != 0
    })

    return {
      id: state.data.id,
      names: names,
      emails: emails,
      phones: phones
    }
  }
}

const actions = {
  ensureLoad(context) {
    console.log("account:ensureLoad")
    if (context.state.loaded) {
      console.log("data already loaded, ignoring ensure load")
    } else {
      console.log("data not loaded, calling perform load")
      context.dispatch('performLoad')
    }
  },

  performLoad(context) {
    console.log("account:performLoad")

    axios.get('accounts/current')
     .then(response => {
        console.log(response)
        context.commit('set_account', response.data)
        context.commit('set_loaded', true)
        context.dispatch('name/add_list', response.data.names, {root:true})
        context.dispatch('phone/add_list', response.data.phones, {root:true})
        context.dispatch('email/add_list', response.data.emails, {root:true})
        context.dispatch('share/add_list', response.data.shares, {root:true})
     })
  },
}

const mutations = {
  set_account(state, value) {
    state.data = value
    state.error = null

    console.log("set account")
    console.log(value)
  },

  set_error(state, value) {
    state.data = null
    state.error = value
  },

  set_loaded(state, value) {
    state.loaded = value
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
