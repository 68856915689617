<template>
  <b-container>
    <b-row>
      <b-col class="kit-live-data-header">
        <h1>
          Emails

          <b-icon icon="question-circle-fill" v-b-popover.click.blur="'You can have different emails to share with different people. The label you use for a email will not be seen when shared, only the email itself.'" ></b-icon>

          <b-icon icon="plus-circle-fill" v-on:click="add"></b-icon>
        </h1>
      </b-col>
    </b-row>
    <LiveEmail v-for="item in emails" :key="item.id" v-bind="item"/>
  </b-container>
</template>

<script>
import LiveEmail from '@/components/LiveEmail.vue'

export default {
  name: 'LiveEmailPanel',
  components: { LiveEmail },
  props: {
    emails: { type: Array, required: true }
  },
  data: function () {
    return {
      adding: false
    }
  },
  methods: {
    add: function() {
      this.$store.dispatch('email/new')
    }
  },
}
</script>

<style scoped lang="scss">
</style>
