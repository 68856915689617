<template>
  <b-container>
    <b-row>
      <b-col class="kit-live-data-header">
        <h1>
          Addresses

          <b-icon icon="question-circle-fill" v-b-popover.click.blur="'You can have different addresses to share with different people. The label you use for a address will not be seen when shared, only the address itself.'" ></b-icon>

          <!-- <b-icon icon="plus-circle-fill" v-on:click="add"></b-icon> -->

          <span>coming soon</span>
        </h1>
      </b-col>
    </b-row>
    <!-- <LiveEmail v-for="item in emails" :key="item.id" v-bind="item"/> -->
  </b-container>
</template>

<script>
//import LiveEmail from '@/components/LiveEmail.vue'

export default {
  name: 'LiveAddressPanel',
//  components: { LiveEmail },
  props: {
    addresses: { type: Array, required: true }
  },
  data: function () {
    return {
      adding: false
    }
  },
  methods: {
    add: function() {
      this.$store.dispatch('address/new')
    }
  },
}
</script>

<style scoped lang="scss">
</style>
