<template>
  <b-container>
    <b-row v-if="authenticated">
      <b-col>
        <p>Authenticated</p>
        <p>email: {{ login.email }}</p>
        <p>token: {{ token }}</p>
        <button v-on:click="performLogout" content="Logout">Logout</button>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <h1>Login</h1>

        <p v-if="error">
        {{ error }}
        </p>

        <b-form @submit="onSubmit" @reset="onReset">

          <b-form-group id="email-group" label="Email:" label-for="email">
            <b-form-input
              id="email"
              v-model="login.email"
              type="email"
              placeholder="myname@example.com"
              required>
            </b-form-input>
          </b-form-group>

          <b-form-group id="password-group" label="Password:" label-for="password">
            <b-form-input
              id="password"
              v-model="login.password"
              type="password"
              placeholder="Super Secure Secret"
              required>
            </b-form-input>
          </b-form-group>

          <b-button type="submit" variant="primary">Submit</b-button>

          <b-button type="reset" variant="danger">Reset</b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'LoginForm',

  data: function() {
    return {
      login: {
        email: null,
        password: null,
      }
    }
  },

  computed: {
    error: function() {
      return this.$store.state.auth.error
    },
    authenticated: function() {
      return this.$store.getters['auth/authenticated']
    },
    token: function() {
      return this.$store.getters['auth/token']
    }
  },

  watch: {
    authenticated: function(newVal, oldVal) {

      if(newVal && !oldVal) {
        this.$rollbar.info('login: atuhenticated became true');
        console.log("authenticated has changed from false to true")

        if(this.$route.params.nextUrl != null){
          this.$router.push(this.$route.params.nextUrl)
        } else {
          this.$router.push('/')
        }
      }

      if(oldVal && !newVal) {
        this.$rollbar.info('login: atuhenticated became false');
        console.log("authenticated has changed from true to false")

        this.$data.login.password = null
      }
    }
  },

  methods: {
    onSubmit: function(event) {
      event.preventDefault()
      this.$rollbar.info('login:onSubmit');
      this.$store.dispatch('auth/login', this.$data.login)
    },
    onReset: function(event) {
      event.preventDefault()
      this.$rollbar.info('login:onReset');
      this.$data.login.email = null
      this.$data.login.password = null
    },
    performLogin: function() {
      this.$rollbar.info('login:performLogin');
      this.$store.dispatch('auth/login', this.$data.login)
    },
    performLogout: function() {
      this.$rollbar.info('login:performLogout');
      this.$store.dispatch('auth/logout')
    }
  },

  created() {
    this.$store.dispatch('auth/loadPersisted')
  },
}
</script>

<style scoped lang="scss">
</style>
