<template>
  <div v-if="emptyContacts">
    <b-container>
      <b-row>
        <b-col>
          <p>No one has shared any details with the email address you registered with yet.</p>
          <p>Please note, we currently only show people details that have been shared with their main account email address. In the future this will be changed to include all email addresses that you verify with us.</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
  <div v-else>
    <GoogleAccount />
    <ContactCardList v-bind:items="items"/>
    <b-container>
      <b-row>
        <b-col>
          <p>Please note, we currently only show people details that have been shared with their main account email address. In the future this will be changed to include all email addresses that you verify with us.</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import GoogleAccount from '@/components/google/GoogleAccount.vue'
import ContactCardList from '@/components/contacts/ContactCardList.vue'

export default {
  name: 'Contacts',
  components: {
    GoogleAccount,
    ContactCardList
  },
  data: function() {
    return {
      error: null,
      showDebug: (process.env.VUE_APP_SHOW_DEBUG_INFO == 'true')
    }
  },
  computed: {
    items: function() {
      return this.$store.getters['contact/all'];
    },
    count: function() {
      return this.$store.getters['contact/count'];
    },
    emptyContacts: function() {
      return this.count == 0;
    },
  },
  mounted() {
    this.$store.dispatch('contact/ensureLoad')
  },
}
</script>
