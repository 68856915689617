<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>Frequently Asked Questions</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="kit-faq-entry">
        <ul>
          <li><a href="#faq-wtf">What is this?</a></li>
          <li><a href="#faq-why">Why did you build this?</a></li>
          <li><a href="#faq-support">Is this service live?</a></li>

          <li><a href="#faq-contact-shared">What happens when I share my details?</a></li>
          <li><a href="#faq-contact-add">How can I add Contacts?</a></li>
          <li><a href="#faq-contact-google">Can I keep my Google contacts?</a></li>
          <li><a href="#faq-contact-outlook">Can I keep my Outlook contacts?</a></li>
          <li><a href="#faq-contact-apple">Can I keep my Apple contacts?</a></li>

          <li><a href="#faq-persona">What is a Persona?</a></li>
          <li><a href="#faq-persona-tags">What are the tags for on data?</a></li>
          <li><a href="#faq-persona-view">How do I know what someone will see?</a></li>

          <li><a href="#faq-data-uses">What will you use my data for?</a></li>
          <li><a href="#faq-data-location">Where will my data be stored?</a></li>
          <li><a href="#faq-data-rights">What rights do I have?</a></li>

          <li><a href="#faq-security">How secure is this?</a></li>

          <li><a href="#faq-question">My question isn't here...</a></li>

        </ul>
        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="kit-faq-entry">
        <h1 id="#faq-wtf">What is this?</h1>

        <p>Kontaktable is an addressbook where you only have to update your own details and decide who to share them with.</p>

        <p>When you make updates everyone you already share with will have up to date information.</p>

        <p>You have fine-grained control over what bits of information you share.</p>

        <p>Details can be synchronised into your existing contact/address software - and this will be automated in the future.</p>
        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="kit-faq-entry">
        <h1 id="#faq-why">Why did you build this?</h1>

        <p>Current address books are backwards and always out of date.</p>

        <p>I never want to update all of my family and friends when I move home again.</p>

        <p>The only details you update in your Kontaktable addressbook are your own.</p>

        <p>People you share them with will always have your most recent information and will be able to get it when they need it.</p>

        <p>There are also the issues of privacy and security that are becoming particularly troubling with systems such as Facebook doing nothing to secure your data.</p>

        <p>We believe your data is yours and shouldn't be sold to people.</p>

        <p>We believe your data is sensitive and should be protected from unauthorized access by people, including us!</p>

        <p>We have ideas of how to ensure that not even we get to see your data but that is a phase 2 goal after finding out if people want this kind of service at all.</p>

        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="kit-faq-entry">
        <h1 id="#faq-support">Is this service live?</h1>
        <p>Yes, no, kind of.</p>
        <p>
          This is a test service at an early stage of development looking for feedback from a small set of users.
        </p>
        <p>
          If you have reached it via a link that has been shared with you by one of our test users we would love to have you also use the system and provide feedback if you have time. If you find you cannot use the Google Synchronisation feature <a :href="helpEmailLink">please email us</a> and we can add you to the users allowed by Google until we have the app verified by them.
        </p>
        <p>
          Please be aware that it may be taken down at any time for maintenance or change.
        </p>
        <p>
          Depending upon feedback the data here may or may not be migrated to a future production system but it is not guaranteed so please do not rely on this to store any data you cannot bear losing.
        </p>

        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="kit-faq-entry">
        <h1 id="#faq-contact-shared">What happens when I share my details?</h1>
        <p>
          Currently the only way to share details is by allowing an email address access.
        </p>
        <p>
          If this email address is registered to a Kontaktable user they will be able to share their details back with you.
        </p>
        <p>
          Either way they will be able to access your details whenever they like.
        </p>
        <p>
          If you have shared your details with someone and expect them to reciprocate ping them a message in case their email is sending our notes to spam, they probably want to keep in touch.
        </p>

        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="kit-faq-entry">
        <h1 id="#faq-contact-add">How can I add Contacts?</h1>
        <p>
          You don't get to decide how many contacts we have, your friends, family and colleagues do.
        </p>
        <p>
          Set up your contact details and share them with other people. They will be given the option of registering and sharing details with you.
        </p>

        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="kit-faq-entry">
        <h1 id="#faq-contact-google">Can I keep my Google contacts?</h1>
        <p>
          Yes.
        </p>
        <p>
          We can update your Google contact list with all of the details people have shared with you.
        </p>
        <p>
          Your contact list contains a button to login to google. Once logged in you can synchronise your Kontaktable contacts to Google.
        </p>
        <p>
          If Kontaktable adds an entry that can be merged with a current Google contact feel free to do this as. Just do not remove the external ID on the contact we have created.
        </p>
        <p>
          In the future this will be fully automated but right now you must manually request that details are synchronised each time.
        </p>
        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="kit-faq-entry">
        <h1 id="#faq-contact-outlook">Can I keep my Outlook contacts?</h1>
        <p>
          We currently don't support Outlook contacts.
        </p>
        <p>
          This is on the long list of TODO.
        </p>

        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="kit-faq-entry">
        <h1 id="#faq-contact-apple">Can I keep my Apple contacts?</h1>
        <p>
          We currently don't support Apple contacts.
        </p>
        <p>
          This is on the long list of TODO.
        </p>

        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="kit-faq-entry">
        <h1 id="#faq-persona">What is a Persona?</h1>
        <p>
          We use the word 'Persona' to encompass the set of data you decide to share with someone else.
        </p>
        <p>
          Our first attempt at being flexible is to allow you to  <b-badge variant="info" class="kit-vistag">tag</b-badge> all your data items with different aspects of your life.
        </p>
        <p>
          Some things are <b-badge variant="info" class="kit-vistag">public</b-badge> and others we only share with <b-badge variant="info" class="kit-vistag">family</b-badge>. If you are part of a <b-badge variant="info" class="kit-vistag">team</b-badge> or <b-badge variant="info" class="kit-vistag">club</b-badge> you might tag some things to be available to them too.
        </p>
        <p>
          When you decide to share your details with someone you can then choose which aspects of your Persona you share with them. They will only see these details and nothing more.</p>
        <p>
          Only what you want to share.
        </p>
        <p>
          Always up to date.
        </p>

        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="kit-faq-entry">
        <h1 id="#faq-persona-tags">What are the tags for on data?</h1>
        <p>
          These represent different aspects of your data you may want to share.
        </p>
        <p>
          For a more concrete example, consider your name. Just like a telephone number you may have more than one for different occasions. You will have a legal name that you're happy to share with <b-badge variant="info" class="kit-vistag">family</b-badge>, a name you use amongst <b-badge variant="info" class="kit-vistag">friends</b-badge> and maybe even a nickname that only your <b-badge variant="info" class="kit-vistag">soccer</b-badge> club knows about.
        </p>
        <p>
          You can express all of these and decide who gets to see different sides of your personality.
        </p>
        <p>
          Having said that we recommend that you use as few different aspects as possible to avoid confusing yourself!
        </p>

        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="kit-faq-entry">
        <h1 id="#faq-persona-view">How do I know what someone will see?</h1>
        <p>
          Every share you offer to people will have this button on it
          <b-button class="kit-inline-button" variant="primary">
            <b-icon icon="eye"></b-icon>
          </b-button>
          that allows you to see your data from their point of view.
        </p>

        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="kit-faq-entry">
        <h1 id="faq-data-uses">What will you use my data for?</h1>
        <p>
          The only thing we will do with your data is share it with people or companies you instruct us to.
        </p>
        <p>
          Simple, huh?
        </p>

        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="kit-faq-entry">
        <h1 id="faq-data-location">Where will my data be stored?</h1>
        <p>
          Short answer: The Cloud.
        </p>
        <p>
          Long answer: The Cloooooooooooooooud.
        </p>
        <p>
          Serious answer: Right now on a single server that might blow up. Once this goes live it will be in multiple geographic locations to make sure we don't lose any of it run by people who we pay money to make sure that the physical boxes don't get tampered with.
        </p>

        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="kit-faq-entry">
        <h1 id="faq-data-rights">What rights do I have?</h1>
        <p>
          We care very deeply that you control your own data and to that end we intend on complying with the EU GDPR legislation as a minimum regardless of your location.
        </p>
        <p>
          You will therefore have all the 8 specific rights afforded under the GDPR;
          <ol>
            <li>The right to be informed</li>
            <li>The right of access</li>
            <li>The right to rectification</li>
            <li>The right to erasure</li>
            <li>The right to restrict processing</li>
            <li>The right to data portability</li>
            <li>The right to object</li>
            <li>Rights in relation to automated decision making and profiling.</li>
          </ol>
        </p>

        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="kit-faq-entry">
        <h1 id="faq-security">How secure is this?</h1>
        <p>
          The current BETA system provides a relatively secure environment for your information.
        </p>

        <p>
          For a full production system we are investigating ways of storing your details in an encrypted format and ensuring that even we never have the ability to decrypt it.
        </p>

        <hr>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="kit-faq-entry">
        <h1 id="faq-question">My question isn't here...</h1>
        <p>
          Feel free to <a :href="helpEmailLink">get in touch using this link</a> and we can try to help you.
        </p>

        <hr>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
export default {
  name: 'Frequently',
  data: function() {
    return {
      helpEmailLink: "mailto:" + process.env.VUE_APP_HELP_EMAIL + "?subject=FAQ",
    }
  }
}
</script>
