<template>
  <div>
    {{ value }}
  </div>
</template>

<script>
export default {
  name: 'ContactName',
  props: {
    value: String
  }
}
</script>

<style>
</style>
