/* eslint-disable */
import Vue from 'vue'
import { GooglePerson } from '@/domain/GooglePerson'
import { domainToGooglePerson, updateGoogleContact } from '@/conversion/google_people'

const SET_INIT = 'SET_INIT';
const RESET_STATE = 'RESET_STATE';
const SET_CURRENT_USER = 'SET_CURRENT_USER';
const SET_SIGNED_IN = 'SET_SIGNED_IN';
const CLEAR_CONTACTS = 'CLEAR_CONTACTS';
const ADD_CONTACT = 'ADD_CONTACT';

const state = {
  init: false,
  signedIn: false,
  currentUser: null,
  contacts: {},
}

const getters = {
  initialised: state => {
    return state.init;
  },

  signedIn: state => {
    return state.signedIn;
  },

  user: state => {
    return state?.currentUser;
  },

  profile: state => {
    return state?.currentUser.getBasicProfile();
  },

  count: state => {
    return Object.keys(state.contacts).length
  },

  all: state => {
    return Object.values(state.data)
  },

}

const actions = {

  initialise(context) {
    if (context.state.init) {
      console.log("google already initialised")
    } else {
      this._vm.$gapi.listenUserSignIn((signedIn) => {
        if (signedIn) {
          context.dispatch('signedIn')
        } else {
          context.dispatch('signedOut')
        }
      })
      context.commit(SET_INIT)
    }
  },

  login(context) {
    console.log("google:login")
    return this._vm.$gapi.login();
  },

  logout(context) {
    console.log("google:logout")
    return this._vm.$gapi.logout();
  },

  signedIn(context) {
    console.log("google:signedIn")

    this._vm.$gapi
      .getCurrentUser()
      .then((user) => {
        context.commit(SET_CURRENT_USER, user)
        context.commit(SET_SIGNED_IN, true)
      })
      .then(() => {
        context.dispatch('refreshContacts')
      });
  },

  signedOut(context) {
    console.log("google:signedOut")

    context.commit(SET_CURRENT_USER, null)
    context.commit(SET_SIGNED_IN, false)
  },

  refreshContacts(context) {
    console.log("google:refreshContacts")

    this._vm.$gapi
      .getGapiClient()
      .then((gc) => {
        gc.client.people.people.connections
          .list({
            resourceName: "people/me",
            personFields: "names,nicknames,emailAddresses,phoneNumbers,addresses,externalIds",
            pageSize: 1000, // TODO: we need to page through them really...
          })
          .then((response) => {
            context.commit(CLEAR_CONTACTS)

            let data = JSON.parse(response.body);

            let contacts = data.connections
              .map((c) => { return new GooglePerson(c) })
              .filter((c) => c.isKontaktable())

            contacts.forEach((contact) => {
              context.commit(ADD_CONTACT, contact)
            })
          });
      });
  },

  updateContact(context, person) {
    console.log("google:updateContact")
    console.log(person)
    console.log(person.kontaktableId)

    let current = context.state.contacts[person.kontaktableId]

    if (current) {
      console.log("updating contact: " + person.toString())
      let body = Object.assign({}, current.data, person.data)
      console.log(body)

      return this.$gapi
        .getGapiClient()
        .then((gapi) => {
          gapi.client.people.people
            .updateContact(
              {
                resourceName: body.resourceName,
                updatePersonFields: "names,nicknames,emailAddresses,phoneNumbers,addresses,externalIds",
              },
              body)
            .then((xxx) => {
              var response = JSON.parse(xxx.body);
              console.log("person update response for " + person.toString())
              console.log(response)
            })
        })
    } else {
      console.log("creating contact: " + person.kontaktableId)

      return this.$gapi
        .getGapiClient()
        .then((gapi) => {
          gapi.client.people.people.createContact(person.data)
            .then((response) => {
              let data = JSON.parse(response.body);

              let newPerson = new GooglePerson(data)
              console.log(newPerson)
              context.commit(ADD_CONTACT, newPerson)
            })
        })
    }
  },
}

const mutations = {

  [SET_INIT] (state) {
    console.log('google:' + SET_INIT);
    state.init = true
  },

  [RESET_STATE](state) {
    console.log('google:' + RESET_STATE);
    state.currentUser = null
  },

  [SET_SIGNED_IN] (state, value) {
    console.log('google:' + SET_SIGNED_IN);
    state.signedIn = value
  },

  [SET_CURRENT_USER] (state, value) {
    console.log('google:' + SET_CURRENT_USER);
    state.currentUser = value
  },

  [CLEAR_CONTACTS](state) {
    console.log('google:' + CLEAR_CONTACTS);
    state.contacts = {}
  },

  [ADD_CONTACT](state, value) {
    console.log('google:' + ADD_CONTACT);
    Vue.set(state.contacts, value.kontaktableId, value)
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
