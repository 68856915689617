<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>Terms of Service</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <p>
          This service is still being actively developed and as such we warn you that at this time we may lose your data, get things wrong and generally make mistakes that might make this service unusable for you.
        </p>
        <p>
          We do promise that we will not sell or give your data to any other company and we are happy for you to let us know that you want all your data removed from our systems.
        </p>
        <p>
          By continuing to use this service you promise NOT to attempt any shenanigans including, but not limited to;
        </p>
        <p>
          <ul>
            <li>Attempt to disrupt the working of any of our services or platforms they are running on.</li>
            <li>Attempt to gain privileged access of any of our services or platforms they are running on.</li>
            <li>Use any of the functionality present in the service to harass or abuse anyone.</li>
          </ul>
        </p>
        <p>
          If you have any questions please <a :href="helpEmailLink">get in touch using this link</a> and we will help you.
        </p>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
export default {
  name: 'TermsOfServiceInfo',
  data: function() {
    return {
      helpEmailLink: "mailto:" + process.env.VUE_APP_HELP_EMAIL + "?subject=Terms of Service",
    }
  }
}
</script>
