<template>
  <div>
    <b-icon icon="telephone"></b-icon> {{ value }}
  </div>
</template>

<script>
export default {
  name: 'ContactPhone',
  props: {
    id: String,
    value: String
  }
}
</script>

<style>
</style>
