import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import Debug from '@/views/Debug.vue'
import Contacts from '@/views/Contacts.vue'
import Account from '@/views/Account.vue'
import Sharing from '@/views/Sharing.vue'
import Login from '@/views/Login.vue'

import Google from '@/views/Google.vue'

import Frequently from '@/views/info/Frequently.vue'
import PrivacyInfo from '@/views/info/PrivacyInfo.vue'
import TermsOfServiceInfo from '@/views/info/TermsOfServiceInfo.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/debug',
    name: 'Debug',
    component: Debug,
    meta: {
      secure: true
    }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    meta: {
      secure: true
    },
    alias: '/'
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      secure: true
    }
  },
  {
    path: '/sharing',
    name: 'Sharing',
    component: Sharing,
    meta: {
      secure: true
    }
  },
  {
    path: '/google',
    name: 'Google',
    component: Google,
    meta: {
      secure: true
    }
  },
  {
    path: '/info/faq',
    name: 'FAQ',
    component: Frequently,
    meta: {
      secure: false
    }
  },
  {
    path: '/info/privacy',
    name: 'Privacy',
    component: PrivacyInfo,
    meta: {
      secure: false
    }
  },
  {
    path: '/info/terms-of-service',
    name: 'Terms of Service',
    component: TermsOfServiceInfo,
    meta: {
      secure: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      secure: false
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    meta: {
      secure: false
    }
  },
  {
    path: '/email-share-token',
    name: 'EmailShareToken',
    component: () => import(/* webpackChunkName: "est" */ '../views/EmailShareToken.vue'),
    meta: {
      secure: false
    }
  },
  {
    path: '/email-share-view',
    name: 'EmailShareView',
    component: () => import(/* webpackChunkName: "esv" */ '../views/EmailShareView.vue'),
    meta: {
      secure: false
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.secure)) {
    console.log("accessing secure page")
    if (store.getters['auth/authenticated']) {
      console.log("authenticated already, continuing")
      next()
    } else {
      console.log("redirecting to login")
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    }
  } else {
    console.log("accessing normal page")
    next()
  }
})

export default router
