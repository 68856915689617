<template>
  <div>
    <b-icon icon="laptop"></b-icon> <a :href="'mailto:' + value">{{ value }}</a>
  </div>
</template>

<script>
export default {
  name: 'ContactEmail',
  props: {
    id: String,
    value: String,
  }
}
</script>

<style>
</style>
