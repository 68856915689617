<template>
  <div id="app">
    <b-container>
    <b-navbar toggleable="md" type="light" variant="primary">
      <b-navbar-brand>{{ title }}</b-navbar-brand>


      <b-navbar-toggle target="navbar-toggle-collapse">
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
          <b-icon v-else icon="chevron-bar-down"></b-icon>
        </template>
      </b-navbar-toggle>

      <b-collapse id="navbar-toggle-collapse" is-nav>

      <b-navbar-nav>
        <b-nav-item to="/contacts" :class="{active: $route.name==='Contacts'}">Contacts</b-nav-item>
        <b-nav-item to="/account" :class="{active: $route.name==='Account'}">My Details</b-nav-item>
        <b-nav-item to="/sharing" :class="{active: $route.name==='Sharing'}">Sharing</b-nav-item>
        <!-- <b-nav-item to="/google" :class="{active: $route.name==='Google'}">Google Test</b-nav-item> -->
        <b-nav-item to="/info/faq" :class="{active: $route.name==='FAQ'}">FAQ</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav v-if="authenticated" class="ml-auto">
        <!-- TOOD: profile/settings menu here for logged in user -->
        <b-nav-item to="/logout"><a v-on:click="performLogout">Logout</a></b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-else class="ml-auto">
        <b-nav-item to="/login" :class="{active: $route.name==='Login'}">Login</b-nav-item>
      </b-navbar-nav>

      </b-collapse>

    </b-navbar>
    </b-container>
<!--
    <b-container>
      <b-row>
        <b-col><p>Buttons</p></b-col>
      </b-row>
      <b-row>

        <b-col><p><b-button variant="primary">Primary</b-button></p></b-col>
        <b-col><p><b-button variant="secondary">Secondary</b-button></p></b-col>
        <b-col><p><b-button variant="success">Success</b-button></p></b-col>
        <b-col><p><b-button variant="warning">Warning</b-button></p></b-col>
        <b-col><p><b-button variant="danger">Danger</b-button></p></b-col>
        <b-col><p><b-button variant="info">Info</b-button></p></b-col>
        <b-col><p><b-button variant="light">Light</b-button></p></b-col>
        <b-col><p><b-button variant="dark">Dark</b-button></p></b-col>

      </b-row>
    </b-container>
-->
    <router-view/>

    <footer class="container-fluid">
      <b-row class="justify-content-md-center">
      <b-col cols="12" md="auto">
      <b-list-group horizontal>
        <b-list-group-item><b-link to="/info/terms-of-service">Terms of Service</b-link></b-list-group-item>
        <b-list-group-item><b-link to="/info/privacy">Privacy</b-link></b-list-group-item>
      </b-list-group>
      </b-col>
      </b-row>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',

  data: function() {
    return {
      title: process.env.VUE_APP_NAME,
    }
  },

  computed: {
    authenticated: function() {
      return this.$store.getters['auth/authenticated']
    }
  },

  methods: {
    performLogout: function() {
      this.$store.dispatch('auth/logout')
    }
  },

  created () {
    document.title = this.$data.title;
  }
}
</script>

<style lang="scss">
</style>
