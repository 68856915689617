import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import './assets/kit.scss'
import Rollbar from 'rollbar';

Vue.prototype.$rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureIp: 'anonymize',
  reportLevel: 'debug',
  payload: {
    environment: process.env.NODE_ENV
  }
});

// If you have already set up a global error handler,
// just add `vm.$rollbar.error(err)` to the top of it.
// If not, this simple example will preserve the app’s existing
// behavior while also reporting uncaught errors to Rollbar.
Vue.config.errorHandler = (err, vm) => {
  vm.$rollbar.error(err);
  throw err; // rethrow
};

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// TODO: remove this when we release or set it only for dev
process.env["NODE_TLS_REJECT_UNAUTHORIZED"] = 0;

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URI;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.interceptors.request.use((config) => {
  const token = store.getters['auth/token'];

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
