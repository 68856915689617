import axios from 'axios'
import Vue from 'vue'

const ENDPOINT = 'contacts'

function fromApi(api) {
  return Object.assign({
    dirty: false,
    forNew: false
  }, api)
}

const state = {
  data: {},
}

const getters = {
  all: state => {
    return Object.values(state.data)
  },
  count: state => {
    return Object.values(state.data).length
  }
}

const actions = {
  // TODO: from name
  // add a list of JSON objects to the store
  add_list(context, arr) {
    console.log("contact:add_list")
    arr.forEach(data => {
      // add properties we want in our store
      context.commit('add', fromApi(data))
    });
  },

  // TODO: from name
  // POST data to server to create a new contact - TODO: convert into an import method
  create(context, data) {
    console.log('contact:create')
    console.log(data)

    context.commit('update', data)

    console.log('call API')
    axios.post(ENDPOINT, data)
      .then(response => {
        console.log("contact create api status: " + response.status + " " + response.statusText)
        console.log('response.data;')
        console.log(response.data)

        context.commit('remove', data.id)
        context.commit('add', fromApi(response.data))
      }, err => {
        console.log("failed to update name " + err)
        // TODO: alert user if we fail to make the change and reset to original somehow?
      })
  },

  // TODO: from name
  update(context, data) {
    console.log('contact:update')
    console.log(data)

    context.commit('add', data)

    console.log('call API to update name')
    axios.put(ENDPOINT + '/' + data.id, data)
      .then(response => {
        console.log(response.data)
        context.commit('add', fromApi(response.data))
      }, err => {
        console.log("failed to update name " + err)
        // TODO: alert user if we fail to make the change and reset to original somehow?
      })

  },

  // TODO: from name
  remove(context, id) {
    console.log('name:remove')
    console.log(id)

    context.commit('set_dirty', id)

    console.log('call API to update name')
    axios.delete(ENDPOINT + '/' + id)
      .then(response => {
        console.log(response.data)
        context.commit('remove', id)
      }, err => {
        console.log("failed to update name " + err)
        // TODO: alert user if we fail to make the change and reset to original somehow?
      })

  },

  ensureLoad(context) {
    console.log("contact:ensureLoad")
    if (context.state.loaded) {
      console.log("data already loaded, ignoring ensure load")
    } else {
      console.log("data not loaded, calling perform load")
      context.dispatch('performLoad')
    }
  },

  performLoad(context) {
    console.log("contact:performLoad")

    axios.get(ENDPOINT)
     .then(response => {
        console.log(response)
        context.dispatch('add_list', response.data)
     })
  },
}

const mutations = {
  // TODO: from name
  set_dirty(state, id) {
    // TODO: test if this makes things work or not
    state.data[id].dirty = true

    //Vue.set(state.data, id, Object.assign({dirty: true}, state.data[id]))
  },

  // TODO: from name
  set_clean(state, id) {
    // TODO: test if this makes things work or not
    state.data[id].dirty = false
  },

  // TODO: from name
  update(state, data) {
    // TODO: test
    Vue.set(state.data, data.id, data)
  },

  // TODO: from name
  add(state, data) {
    // TODO: test
    Vue.set(state.data, data.id, data)
  },

  // TODO: from name
  remove(state, id) {
    // TODO: test
    Vue.delete(state.data, id)
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
