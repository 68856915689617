<template>
  <b-container fluid="sm">
    <b-row>
      <b-col class="kit-live-data-header">
        <h1>
          Names

          <b-icon icon="question-circle-fill" v-b-popover.click.blur="'You can have different names to share with different people. The label you use for a name will not be seen when shared, only the name itself.'" ></b-icon>

          <b-icon icon="plus-circle-fill" v-on:click="addName"></b-icon>
        </h1>
      </b-col>
    </b-row>
    <LiveName v-for="item in names" :key="item.id" v-bind="item"/>
  </b-container>
</template>

<script>
import LiveName from '@/components/LiveName.vue'

export default {
  name: 'LiveNamePanel',
  components: { LiveName },
  props: {
    names: { type: Array, required: true }
  },
  data: function () {
    return {
      adding: false
    }
  },
  methods: {
    addName: function() {
      this.$store.dispatch('name/new')
    }
  },
}
</script>

<style scoped lang="scss">
</style>
