<template>
  <b-container>
    <b-row v-for="item in items" :key="item.id" v-bind="item">
      <b-col>
        <ContactCard v-bind="item"/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ContactCard from '@/components/contacts/ContactCard.vue'

export default {
  name: 'ContactCardList',
  components: {
    ContactCard
  },
  props: {
    items: Array
  }
}
</script>
