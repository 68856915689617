<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>Privacy</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <p>
          We take data privacy seriously.
        </p>
        <p>
          We will never reveal your information to someone you did not expressley ask us to.
        </p>
        <p>
          A more detailed statement will be developed before this system is fully live.
        </p>
        <p>
          If you have any questions please <a :href="helpEmailLink">get in touch using this link</a> and we will help you.
        </p>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
export default {
  name: 'PrivacyInfo',
  data: function() {
    return {
      helpEmailLink: "mailto:" + process.env.VUE_APP_HELP_EMAIL + "?subject=Privacy",
    }
  }
}
</script>
