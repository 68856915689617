<template>
    <b-card class="kit-contact-card" :title="title">
      <b-card-text>
      <ContactName v-for="(item, index) in names" :key="'name-' + index" v-bind="item" />
      <ContactEmail v-for="(item, index) in emails" :key="'email-' + index" v-bind="item" />
      <ContactPhone v-for="(item, index) in phones" :key="'tel-' + index" v-bind="item" />
      </b-card-text>
    </b-card>
</template>

<script>
import ContactName from '@/components/contacts/ContactName.vue'
import ContactEmail from '@/components/contacts/ContactEmail.vue'
import ContactPhone from '@/components/contacts/ContactPhone.vue'

export default {
  name: 'ContactCard',
  components: {
    ContactName,
    ContactEmail,
    ContactPhone
  },
  props: {
    id: { type: String, required: true },
    names: { type: Array, required: false, default: function() { return [] } },
    emails: { type: Array, required: false, default: function() { return [] } },
    phones: { type: Array, required: false, default: function() { return [] } }
  },

  computed: {
    title: function() {
      let primaryName = this.$props.names[0]

      // TODO: something for contacts without a primary name
      return (primaryName?.value || 'UNKNOWN NAME')
    }
  },
}
</script>

<style lang="scss">
.kit-contact-card {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.kit-vistag {
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.kit-inline-button {
  margin-left: 0.25em;
  margin-right: 0.25em;
}
</style>
