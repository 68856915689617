<template>
  <b-container v-if="loaded">
    <LiveNamePanel v-bind:names="names" />
    <LivePhonePanel v-bind:phones="phones" />
    <LiveEmailPanel v-bind:emails="emails" />
    <LiveAddressPanel v-bind:addresses="addresses" />
  </b-container>
  <b-container v-else>
    <b-row>
      <b-col><h2>Loading...</h2></b-col>
    </b-row>
  </b-container>
</template>

<script>
import LiveNamePanel from '@/components/LiveNamePanel.vue'
import LivePhonePanel from '@/components/LivePhonePanel.vue'
import LiveEmailPanel from '@/components/LiveEmailPanel.vue'
import LiveAddressPanel from '@/components/LiveAddressPanel.vue'

export default {
  name: 'Account',
  components: { LiveNamePanel, LivePhonePanel, LiveEmailPanel, LiveAddressPanel },
  data: function() {
    return {
      error: null,
      showDebug: (process.env.VUE_APP_SHOW_DEBUG_INFO == 'true')
    }
  },
  computed: {
    loaded: function() {
      return this.$store.getters['account/loaded']
    },
    account: function() {
      return this.$store.getters['account/current']
    },
    names: function() {
      return this.$store.getters['name/all']
    },
    phones: function() {
      return this.$store.getters['phone/all']
    },
    emails: function() {
      return this.$store.getters['email/all']
    },
    addresses: function() {
      return []
    }
  },
  mounted() {
    console.log("account mounted...")
    this.$store.dispatch('account/ensureLoad')
  },
}
</script>

<style scoped lang="scss">
</style>
